@charset 'UTF-8';
@import "/Users/naomi/Sites/saga-local/src/scss/utility/_flex.scss";
@import "/Users/naomi/Sites/saga-local/src/scss/utility/_media-queries.scss";
@import "/Users/naomi/Sites/saga-local/src/scss/utility/_mixin.scss";


.en{
	@include letter-spacing(50);
	[class*="txt"]{
		text-align: left;
	}
	.c-title01{
		@include letter-spacing(50);
	}
	.main-visual{
		.main-title{
			@include letter-spacing(75);
			@include ffLT;
		}
	}
	.sec-concept .txt01{
		letter-spacing: 0.05em;
		margin-right: -8px;
	}
	.sec-beer .txt01{
		letter-spacing: 0.05em;
	}
	@include min-screen(1500px) {
		.sec-beer .block-born .wcm{padding-left: 11% !important;}
	}
	@include PC {
		.main-visual{
			.main-title{
			    line-height: 36px;
			}
		}
		.sec-concept{
			.box{
			    padding-left: 295px;
			}
			.txt01{
			    margin-top: 39px;
			    @include PC {
					line-height: 29px;
					@include font-size(13);
			    }
			}
			.bg-parallax{
				margin: 89px 0 61px;
			}
		}
		.sec-beer{
		    margin: 180px 0 190px;
			.block-beer{
				padding-bottom: 183px;
				.txt01{
					margin-top: 80px;
					@include PC {
						line-height: 29px;
						@include font-size(13);
					}
				}
			}
			.block-born{
				margin-top: -50px;
				.desc{
					margin-bottom: 12px;
				}
				.wcm{padding-left: 18%;}
				.txt02{
					line-height: 20px;
					margin-top: 16px;
				}
				.txt02_1{
					margin-bottom: 15px;
					letter-spacing: 0.02em;
					margin-top: 12px;
				}
				.c-btn1{margin-top: 16px;}

			}
		}
	}
	@include max-screen(1100px) {
		.sec-beer .block-born .wcm{
			padding-left: 10%;
		}
	}
	@include SP {
		.main-visual .main-title{
			margin-top: -35px;
			line-height: 1.636;
			@include letter-spacing(20);
			@include font-size(11);
		}
		.c-title01{
			line-height: 1.533;
			@include letter-spacing(20);
			@include font-size(15);
		}
		.sec-concept{
			.bg-parallax{
				margin: 38px 3px 42px;
			}
			.c-btn1{
				margin-top: 37px;
			}
			.txt01{
				line-height: 1.538;
				@include letter-spacing(20);
				@include font-size(13);
			}
		}
		.sec-beer{
			margin-top: 60px;
			padding-bottom: 30px;
			.inner{
				margin-top: -27px;
			}
			.block-beer{
				padding-bottom: 63px;
				.txt01{
					line-height: 1.538;
					@include letter-spacing(20);
					@include font-size(13);
				}
			}
			.block-born{
			    margin-top: -25px;
			    .photo{
			    	max-width: 124px;
			    	margin-right: 10px;
			    }
				.wcm{
				    padding-left: 41px;
				}

				.c-btn1{
				    margin-top: 36px;
				}
				.desc{
					margin-bottom: -3px;
					.txt02{
						margin-bottom: 6px;
						line-height: 1.375;
						font-size: 8px;
					}
					.txt02_1{
						margin-bottom: 12px;
					}
				}
				.btn1{
					margin-top: -18px;
				}
				.txt03{
					margin-top: 22px;
					font-size: 8px;
					line-height: 1.375;
				}
			}
		}
	}
	@include max-screen(350px) {

		[class*="txt"]{
			letter-spacing: 0 !important;
		}
		.c-inner{
			padding: 0 10px;
		}
		.sec-beer .block-born .desc{
			padding-top: 0;
			.txt02_1{
				margin-top: 0;
			}
		}
		.sec-beer .block-born{
			.wcm{
				padding-left: 20px;
			}
			.photo{
				margin-right: 10px;
			}
		}
	}
}

.main-visual{
	position: relative;
	height: 100vh;
	min-height: 568px;
	pointer-events:none;
	&:before{
		position: absolute;
		content: "";
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: #000;
		opacity: 1;
		z-index: 8;
	}
	&.swiper-container-initialized{
		&:before{
			opacity: 0;
			transition: 1.5s ease-in-out;
		}
	}
	.swiper-slide .bg-load{
	    position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center bottom;
		transition: transform 6s ease-in-out;
		transform: scale(1);
	}
	.swiper-slide-active .bg-load{
		transform: scale(1.1);
		transition: transform 6s ease-in-out;
	}
	.swiper-slide{
		overflow: hidden;
		&.hide-slide{
			display: none !important;
		}
	}

	.main-title{
		position: absolute;
		top: 50%;
		transform:translateY(-50%);
		max-width: 980px;
		padding: 0 40px;
		color: #fff;
		line-height: 40px;
		font-weight: bold;
		letter-spacing: 5.5px;
		margin: -58px 0 0 8.6%;
		z-index: 9;
		opacity: 0;
		@include font-size(21);
		@include letter-spacing(300);
		&.on{
			opacity: 1;
			transition: 1.5s .3s;
		}
	}
	@include max-screen(1080px){
		min-height: auto;
		max-height: 786px;
		.main-title{
			line-height: 19px;
			margin-left: 0;
			@include font-size(16);

		}
		.slide01 .bg-load{
			background-position: right 25% bottom;
		}
	}
	@include max-screen(767px){
		min-height: auto;
		max-height: 586px;
		.main-title{
			margin-top: -47px;
			@include font-size(11);
		}
	}
	@include max-screen(450px) {
		min-height: auto;
	    max-height: 375px;
		.main-title{
			margin-left: 6px;
			padding-left: 30px;
			letter-spacing: 0.2em;
			margin-top: 0;
			line-height: 1.71;
			margin-top: -35px;
			@include font-size(14);
		}
		.slide01 .bg-load{
			background-position: right 25% bottom;
		}
	}
}

.sec-movie{
	padding-top: 117px;
	position: relative;
	@include SP {
		padding-top: 50px;
	}

	.wcm{
		padding: 0;
	}

	.box{
		position: relative;
		@include SP {
			padding: 0 40px;
		}

		.c-title03{
			@include PC {
				position: absolute;
				top: -53px;
				right: -63px;
			}
		}

		.block01{
			.c-title01{

			}
			.txt01{
				margin-top: 41px;
				@include SP {
					margin-top: 25px;
					line-height: 1.785714;
					@include letter-spacing(80);
					@include font-size(14);
				}
			}
		}
	}

	.youtube{
		margin-top: 110px;
		position:relative;
		width:100%;
		height:0;
		padding-top: 56.25%;
		@include SP {
			margin-top: 55px;
		}

		iframe{
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
		}
	}
}

.sec-concept{
    padding-top: 120px;
    position: relative;
	.box{
		position: relative;
		padding-left: 330px;
	}
	.txt01{
	    margin-top: 41px;
	    letter-spacing: 0.2em;
	    @include PC {
	    	// font-size: 16px;
	    	// line-height: 35px;
	    }
	}
	.c-title03{
		position: absolute;
	    height: 290px;
	    top: -53px;
	    left: -63px;
	}
	.bg-parallax{
	    margin: 117px 0 61px;
		// background-image: url(../../img/top/parallax01.jpg);
		// height: 450px;
	}
	.block01{
		// float: right;
	}
	.block02{
		padding-top: 60px;
	}
	.c-btn1{
		margin-top: 37px;
	}
	@include max-screen(1100px) {
		.box{
			padding-left: 100px;
		}
		.c-title03{
			left: 0;
		}
	}
	@include SP {
		padding-top: 60px;
		.box{
			padding-left: 20px;
		}
		.c-title03{
			left: 0;
			top: 0;
			height: auto;
			position: relative;
		}
		.block01{
			float: none;
		}
		.txt01{
			margin-top: 23px;
			line-height: 1.785714;
			@include letter-spacing(80);
			@include font-size(14);
		}
		.bg-parallax{
			margin: 41px 0 1px;
			// height: 180px;
		}
		.block02{
			padding-top: 40px !important;
		    padding-right: 10px;
		}
		.btn1{
			padding: 0 20px;
		}
		.c-btn1{margin-top: 30px;}
	}
	@include max-screen(360px) {
		.txt01{
			letter-spacing: 0.05em;
			@include font-size(12);
		}
		.c-inner{padding: 0 10px;}
		// .bg-parallax{background-size: 155%;}
		.btn1{padding: 0 10px;}
	}
}
.preload [data-paroller-factor] {
   transition: 1s;
}
.sec-beer{
    margin: 140px 0 172px;
	.bg-parallax{
		// background-image: url(../../img/top/parallax02.jpg);
		// height: 800px;
		// @include min-screen(1100px){
		//     background-position: center top !important;
		//     background-position-x: 70% !important;
		//     background-attachment: fixed;
		//     background-size: cover !important;
		// }
	}

	.banner{
		@include PC {
			max-height: 800px;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			img{
				opacity: 0;
			}
		}
	}


	.inner{margin-top: -155px;}
	.block-beer{
		position: relative;
		color: #fff;
		z-index: 1;
		padding-top: 86px;
		padding-bottom: 148px;
		&:after{
			position: absolute;
			content: "";
			background: $blue1;
			top: 0;
			right: 0;
			width: calc(50% + 400px);
			height: 100%;
			z-index: -1;
		}
		.c-title03{@include PC {height: 150px;}}
		.wcm{padding-left: 12.5%;}
		.content{
		    margin-left: 14.8%;
		    margin-top: 30px;
		}
		.txt01{
			margin-top: 41px;
			@include PC {
				// font-size: 16px;
				// line-height: 35px;
			}
		}
	}
	.block-born{
	    position: relative;
	    z-index: 1;
	    margin-top: -36px;
	    .fade01.on{
			transition-delay: .1s;
		}
		.fade02.inview.on{
			transition-delay: .3s !important;
		}
		.fade03.on{
			transition-delay: .5s;
		}
		.wcm{
	        padding-left: 15%;
			@include flexbox;
			@include align-items(flex-end);
			@include PC {
				@include justify-content(center);
			}
		}
		.photo{
			max-width: 234px;
			width: 100%;
		    margin-right: 25px;
		}
		.desc{
			transition-delay: 0.3s;
		    margin-right: -50px;
		}
		.txt02{
			// font-weight: bold;
			line-height: 20px;
			@include letter-spacing(20);
			@include font-size(12);
			a{
				color: #259bde;
				font-weight: bold;
				&:hover{
					@include PC {
						text-decoration: underline;
					}
				}
			}

			@include min-screen(1700px) {
				// @include font-size(13);
			}
		}
		.txt03{
		    margin-bottom: 4px;
		}
		.txt02_1{
        	margin-top: 10px;
			@include letter-spacing(20);
		}
		.txt04{
			margin-top: 8px;
			margin-bottom: -4px;
		}
		.c-btn1{
		    margin-top: 16px;
		    margin-bottom: 17px;
		}
	}
	.pointer-none{
		pointer-events: none;
	}
	@include max-screen(1100px) {
		.block-born{
			.wcm{padding-left: 0;}
		}
		.block-beer{
			&:after{
				width: 92%;
			}
		}
		.bg-parallax{
			// background-size: 150%;
		}
	}
	@include SP {
		margin: 75px 0 72px;
		// .bg-parallax{
		// 	height: 295px;
		// 	background-size: 180%;
		// 	background-position-x: 70% !important;
		// }
		.block-beer{
			padding-top: 43px;
		    padding-bottom: 98px;
			.wcm{padding-left: 41px;}
			.content{
				margin-left: 0;
				margin-top: 0;
			}
			&:after{width: calc(100% - 21px);}
			.txt01{
				line-height: 1.785714;
				@include font-size(14);
				letter-spacing: 0.08em;
				margin-top: 20px;
			}
		}
		.inner{margin-top: -28px;}
		.c-title03{
			@include font-size(16);
			&:after{
				background: #fff;
			}
		}

		.block-born{
		    margin-top: -35px;
		    .wcm{
		    	padding-left: 40px;
		    }
		    .desc{
    	        padding-top: 75px;
    	        margin-bottom: 5px;
    	        .txt02{
    	        	margin-top: -50px;
    	        	letter-spacing: 0.02em;
	    	        margin-bottom: 5px;
	    	        line-height: 1.625;
	    	        font-size: 8px;
    	        }
    	        .txt02_1{
    	        	margin: 0;
    	        	margin-top: 13px;
    	        }
		    }
	        .txt03{
	        	margin-left: 40px;
        	    font-size: 9.5px;
			    line-height: 16px;
	        }
	        .txt03_1{
        	    margin-top: 9px;
	        }
			.photo{
				max-width: 123px;
			    margin-right: 17px;
			}
			.btn1{
				padding: 0 40px;
				margin-top: 17px;
			}
			.txt03{
				font-size: 8px;
				line-height: 1.625;
			}
		}
		@include max-screen(350px) {
			.block-born{
				.btn1{
					padding: 0 30px;
				}
			}
		}
	}
	@include max-screen(350px) {
		.bg-parallax{
			// background-size: 200%;
		}
		.block-beer{
			&:after{width: calc(100% - 20px);}
			.txt01{
				@include font-size(11);
			}
		}
		.block-born{
			.photo{
				max-width: 110px;
				margin-right: 10px;
			}
			.wcm{
				padding-left: 35px;
			}
			.desc{
				.txt02{
				}
				.txt02_1{
					margin-top: 8px;
				}
			}
			.c-btn1{

			}
		}
	}
}

.sec-topics{
	margin-bottom: 127px;
	.c-title02{
		text-align: center;
	    margin-bottom: 51px;
	}
	.c-btn1{margin-top: 52px;}
	@include SP {
		margin-bottom: 72px;
		.c-title02{
		    margin-bottom: 26px;
		}
		.c-btn1{margin-top: 38px;}
	}
	@include max-screen(350px) {
		.wcm{
			padding: 0 30px;
			ul{
				// padding: 0 10px;
			}
		}
	}
}

// english
